.nav {
  width: 100%;
  height: 4.5rem;
  background-color: var(--bg);
  position: sticky;
  top: 0;
  transition: var(--quick-1) var(--expressive);
  z-index: 3000;

  &.has-scrolled {
    box-shadow: 0px 0px 10px 0px #18405e2e;
  }

  & .nav-container {
    width: 100%;
    height: 100%;
    padding: 1.25rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  & .get-started-button {
    margin-right: 1.5rem;
    height: 2rem;
    display: flex;
    align-items: center;
    padding: 0.3125rem 0.5rem 0.375rem 0.375rem;
    gap: 0.25rem;
    border: 1px solid #d5d8dc;
    font-weight: 400;
    font-size: 1rem;
    line-height: 120%;
    color: var(--fg-default);
    border-radius: 0.375rem;

    svg {
      scale: 0.75;
    }

    &:hover {
      background: #f1f3f4;
      border-width: 1px;
      border-color: #d5d8dc;
    }
  }
}

.nav-menu {
  width: 17.5rem;
  border-radius: 6px !important;
  padding: 0.5rem 0 !important;
  flex-direction: column;

  .menu-action {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 1rem 0 1.125rem;
    height: 2.5rem;
    cursor: pointer;
    color: var(--fg-default);
    font-weight: 400;
    font-size: 1rem;
    line-height: 134%;

    svg {
      margin: 0 0.625rem 0 0.25rem;
    }

    &:hover {
      background: var(--item-hover);
    }

    &:active {
      background: var(--item-hover);
    }
  }

  .menu-separator {
    margin: 0.5rem 0;
  }
}

.org-menu-button {
  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
  height: 2rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 134%;
  background: #ffffff;
  display: flex;
  align-items: center;
  color: var(--fg-default);

  border-radius: 6px;
  gap: 0.125rem;

  &:hover {
    background: var(--bg-a);
  }

  .issues-icon {
    margin-right: 0.125rem;
    display: flex;
    align-items: center;
  }

  .chevron-icon {
    display: flex;
    align-items: center;
    scale: 0.75;
  }
}

.org-menu {
  .org-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.375rem 1rem 0.375rem 1.125rem;

    &.clickable {
      cursor: pointer;

      &:hover {
        background: var(--item-hover);
      }
    }

    .left {
      display: flex;
      flex-direction: column;
      gap: 0.125rem;
      width: 100%;
      align-items: flex-start;

      .label {
        color: var(--fg-tertiary);
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 140%;
      }

      .name {
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 132%;
      }
    }
  }

  .back-item {
    padding: 0.5rem 1rem 0.5rem 0.625rem;

    svg {
      margin: 0 !important;
    }
  }

  .org-item {
    justify-content: space-between;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
      text-align: left;
    }

    svg {
      margin: 0 !important;
    }
  }
}

.account-menu {
  .user-info {
    width: 100%;
    text-align: left;
    padding: 0.5rem 1rem;

    .name {
      font-weight: 600;
      font-size: 1rem;
      line-height: 134%;
    }
  }
}

.mobile-menu {
  width: 270px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  box-shadow: var(--shadow-sm); // temporary
}

@mixin hide() {
  display: none;
  visibility: hidden;
}

@mixin show() {
  display: flex;
  visibility: visible;
}

.desktop-menu-wrapper,
.mobile-menu-wrapper {
  position: relative;
}

.desktop-menu-wrapper {
  // @include hide();
}

.mobile-menu-wrapper {
  @include hide();
}

@media only screen and (max-width: 720px) {
  .desktop-menu-wrapper {
    @include hide();
  }

  .mobile-menu-wrapper {
    @include show();
  }
}
