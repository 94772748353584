// Components should generally be styled individually, but sometimes
// having global classnames can be useful.

.inline-link {
  color: var(--hyperlink-dark);
  text-decoration: none;
  transition: var(--quick-1) var(--expressive);

  &:hover {
    filter: brightness(1.2);
  }
}

.s-input {
  appearance: none;
  min-width: 12.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  background-color: #f3f5f7;
  box-shadow: none;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  transition: var(--quick-2) var(--expressive);

  &:focus {
    outline: none;
    background-color: white;
    border-color: #078fdb;
    box-shadow: 0px 0px 0px 3px rgba(182, 242, 255, 0.4);
  }

  &.error {
    background-color: #fbf2f2;
    border-color: #e36857;
    box-shadow: 0px 0px 0px 3px #fbf2f2;

    &:focus {
      background-color: white;
    }
  }

  &.lg {
    min-width: 16rem;
  }

  &:disabled {
    color: #b9bec5;
    cursor: not-allowed;
  }
}

a {
  &.underline {
    &:hover {
      text-decoration: underline;
    }
  }
}
