.location-input-wrap {
  & .immediate-input-wrap {
    position: relative;
  }

  & .spinner-wrap {
    position: absolute;
    top: 0.75rem;
    right: 1rem;
    z-index: 2;
  }
}

.location-menu {
  width: 100%;
  max-width: 514px; // The width of the input
  position: absolute;
  top: 3rem;
  padding: 0.5rem 0;
  background-color: white;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3),
    0px 2px 16px 2px rgba(15, 22, 28, 0.15);
  border-radius: 0.375rem;
  transform-origin: top center;
  will-change: transform;
  z-index: 3;

  & button {
    width: 100%;
    height: 3rem;
    appearance: none;
    padding: 0 1.5rem;
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    transition: var(--quick-1) var(--expressive);

    &:hover {
      background-color: var(--gray-base);
    }

    // The icon
    & .MuiBox-root {
      margin-right: 0.5rem;
    }

    & p {
      font-size: 1rem;
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0;
    }
  }
}
