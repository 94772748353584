.alert-dialog {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(38, 40, 41, 0.4);
  padding: 2rem;
  z-index: 4000;

  & .inner {
    width: 100%;
    max-width: 440px;
    background-color: var(--bg);
    border-radius: 1.5rem;
    box-shadow: 0px 2px 30px rgba(38, 41, 46, 0.35);
    overflow: hidden;
    will-change: transform;
  }

  // The entire surface, minus the actions area
  & .content {
    padding: 2.5rem 1.5rem;

    & .alert-grid {
      display: grid;
      grid-template-columns: 1.5rem 1fr;
      gap: 1.5rem;
    }

    & .title {
      font-size: 1.3125rem;
      font-weight: 600;
      line-height: 132%;
    }

    & p {
      color: var(--fg-secondary);
      font-size: 0.875rem;
      line-height: 134%;
      margin-top: 0;

      &:not(:last-of-type) {
        margin-bottom: 0.5rem;
      }
    }
  }

  // Alert buttons & actions area
  & .actions {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 1rem;
    gap: 0.5rem;

    & button {
      appearance: none;
      height: 2.5rem;
      padding: 0.4375rem 1rem;
      background-color: #f4f6f8;
      border: 1px solid #d3d9dd;
      gap: 0.25rem;
      border-radius: 0.625rem;
      appearance: none;
      transition: var(--quick-2) var(--expressive);
      min-width: 130px;

      &:hover {
        background-color: #d5d8dc;
      }

      &,
      & span {
        font-size: 1rem;
        font-weight: 600;
        line-height: 134%;
      }

      &.primary {
        background-color: var(--accent);
        border-color: var(--accent);
        color: white;

        &:hover {
          background-color: var(--accent-hover);
        }
      }

      &.danger {
        background-color: var(--danger);
        border-color: var(--danger);
        color: white;

        &:hover {
          filter: brightness(0.9);
        }
      }
    }
  }
}
