.sequence-modal {
  display: flex;
  flex-direction: column;
  min-height: 0;

  & .screen-wrap {
    width: 100%;
    min-height: 0;
    flex: 1;
    display: flex;

    & .screen {
      width: 100%;
      padding: 2rem 2rem 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      & .scrollable-content {
        width: 100%;
      }
    }
  }
}
