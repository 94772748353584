.no-select {
  user-select: none;
}

.wfull.propagate {
  width: 100%;

  & > div {
    width: 100%;
  }

  & .MuiInput-input {
    width: 220px;
  }
}
