.linked-account-modal-new {
  & .screen {
    padding: 2rem 2rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  & .devices-list {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #dde0e2;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;

    & .header {
      width: 100%;
      padding: 0.25rem 1rem;
      background-color: #dde0e2;
      position: sticky;
      top: 0;
      z-index: 2;

      & .header-action {
        appearance: none;
        background-color: transparent;
        color: #078fdb;
        font-size: 0.9rem;
      }

      & p {
        color: #6e7179;
        margin: 0;
      }
    }

    & .device-row {
      height: 3.5rem;
      padding: 0 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      border-bottom: 1px solid var(--row-border);
      cursor: pointer;
      transition: var(--quick-2) var(--expressive);

      &:hover,
      &.is-selected {
        background: var(--row-bg);
      }

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}
