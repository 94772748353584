.access-pass-create-modal {
  & .text {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3;
  }

  // DeviceListScreen
  & .device-box {
    width: 100%;
    border: 1px solid #dde0e2;

    & .device-row {
      padding: 1rem 1.5rem;
      border-bottom: 1px solid #dde0e2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      cursor: pointer;
      transition: var(--d-m-2) var(--e-s);

      &:hover {
        background-color: #e4e8eb;
      }

      &.selected {
        opacity: 0.5;
      }

      &:last-of-type {
        border-bottom: none;
      }

      & .image-wrap {
        min-width: 2rem;
        max-width: 2rem;
        margin-right: 1rem;

        & img {
          max-width: 100%;
          height: 2rem;
          margin-right: 1rem;
        }
      }

      & p {
        font-size: 1rem;
        font-weight: 600;
        margin: 0;

        &.device-model {
          color: #9da1a9;
          font-weight: 400;
        }
      }
    }
  }

  & .detail-label-wrap {
    padding-top: 1rem;
    padding-bottom: 0.5rem;

    & h3 {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 134%;
    }
  }
}
