.role-menu {
  position: relative;

  & .icon.chevron {
    transition: var(--quick-2) var(--expressive);

    &.is-open {
      // An additional translation is needed to center the rotated icon
      transform: rotate(180deg) translateY(1px);
    }
  }
}

.role-menu-list {
  width: 318px;
  padding: 0.5rem 0;
  background: white;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3),
    0px 2px 16px 2px rgba(15, 22, 28, 0.15);
  border-radius: 0.5rem;

  & .role-item {
    appearance: none;
    width: 100%;
    height: 3.5rem;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background-color: transparent;
    transition: var(--quick-2) var(--expressive);

    &:hover {
      background-color: #f4f6f8;
    }

    &:active {
      background-color: #dde0e2;
    }

    &.variant-danger {
      & .label {
        color: var(--danger);
      }
    }

    & .icon {
      margin-right: 0.625rem;
    }

    & p {
      margin: 0;
    }

    & .label {
      font-size: 1rem;
      font-weight: 600;
      line-height: 134%;
      color: var(--fg-default);
    }

    & .caption {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 140%;
      color: var(--fg-secondary);
    }
  }

  & .role-menu-description {
    font-size: 0.875rem;
    line-height: 1.1725rem;
    color: var(--fg-secondary);
    margin: 0;
  }

  & .separator {
    margin: 0.5rem 0;
    width: 100%;
    height: 1px;
    background-color: #eee;
  }
}
