.text-default {
  color: var(--fg-default);
}

.text-secondary {
  color: var(--fg-secondary);
}

.text-tertiary {
  color: var(--fg-tertiary);
}

.text-dimmed {
  color: var(--fg-dimmed);
}

strong:not([class*="fw-"]) {
  font-weight: 600;
}
