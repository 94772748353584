.device-time-picker {
  width: 100%;
  border: 1px solid #d5d8dc;
  border-radius: 0.75rem;
  padding: 1rem 1.5rem;

  & .picker-caption {
    font-size: 0.875rem;
    color: #9da1a9;
    margin: 0;

    &.italic {
      font-style: italic;
    }
  }

  & .tracks {
  }

  & .track-label {
    font-size: 0.875rem;
    font-weight: 600;
    color: #6e7179;
    margin: 0;
  }

  // Date
  & .date-picker {
    position: relative;
  }

  & .dynamic-wrapper {
    position: absolute;
    bottom: 3rem;
    box-shadow: 0 2px 3px 3px var(--bg-200);
    border-radius: 1rem;
    transform-origin: bottom left;
  }

  // Time
  & .time-picker {
    margin-top: 0.5rem;

    & .fake-input {
      appearance: none;
      width: 100%;
      height: 2.5rem;
      padding: 0.5rem;
      background-color: #f3f5f7;
      box-shadow: none;
      border: 1px solid transparent;
      border-radius: 0.375rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      transition: var(--quick-2) var(--expressive);

      &:focus-within {
        outline: none;
        background-color: white;
        border-color: #078fdb;
        box-shadow: 0px 0px 0px 3px rgba(182, 242, 255, 0.4);
      }
    }

    & button {
      appearance: none;
      // width: 22px;
      // height: 24px;
      background-color: transparent;
      padding: 0.125rem;
      // padding: 0;
      border-radius: 3px;
      transition: var(--duration-fast-02) var(--expressive);

      &:focus {
        outline: none;
      }

      &:hover,
      &:focus-visible,
      &.active {
        background: rgba(106, 193, 255, 0.5);
      }

      &.meridiem {
        // padding: 0 0.125rem;
        margin-left: 0.125rem;
      }
    }

    & p {
      margin: 0;
    }
  }
}
