// While Menu is an abstract component with no default styling,
// this generic file provides some global defaults.

.default-menu {
  width: 318px;
  padding: 0.5rem 0;
  background: white;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3),
    0px 2px 16px 2px rgba(15, 22, 28, 0.15);
  border-radius: 0.5rem;
  position: absolute;
  top: 3rem;
  right: 0;

  &.size-sm {
    width: 229px;
  }

  & .item,
  & button:not([class]),
  & li {
    appearance: none;
    width: 100%;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background-color: transparent;
    transition: var(--quick-2) var(--expressive);

    &:hover {
      background-color: #f4f6f8;
    }

    &:active {
      background-color: #dde0e2;
    }

    &.danger {
      color: var(--danger);
    }

    & .label,
    & .caption {
      margin: 0;
    }

    & .label {
      font-size: 1rem;
      font-weight: 600;
      line-height: 134%;
      color: var(--fg-default);
    }

    & .caption {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 140%;
      color: var(--fg-secondary);
    }
  }

  &.large-items {
    & .item,
    & button:not([class]) {
      height: 3rem;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  & .separator {
    height: 1px;
    width: 100%;
    background-color: var(--line-surface);
    margin: 0.5rem 0;
  }
}
