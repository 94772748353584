.access-pass-page {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 2rem 0;
  background-color: rgb(250, 248, 248);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & .access-pass {
    max-width: 30rem;
    background-color: white;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 2px 12px 1px rgba(black, 0.1);

    & .image {
      width: 100%;
      height: 15rem;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      overflow: hidden;
      position: relative;

      & .banner {
        width: 100%;
        position: absolute;
        bottom: 0;
        background: rgba(5, 7, 26, 0.5);
        color: white;
        padding: 0.5rem 1rem;

        & p {
          margin: 0;
        }

        & i path {
          fill: white;
        }
      }

      & .logo {
        width: 2.5rem;
        height: 2.5rem;
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        background: radial-gradient(
          63.13% 50% at 50% 50%,
          #0f1737 0%,
          #0f1737 100%
        );
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 90px;

        & img {
          filter: invert(1);
        }
      }
    }
  }

  & .tile {
    padding: 0.5rem 0.75rem 0.6125rem;
    height: 3rem;
    background: white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    & span {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1;
      margin: 0;
    }
  }
}

@media only screen and (max-width: 600px) {
  .access-pass-page {
    padding: 0;

    & .access-pass {
      width: 100vw;
      max-width: none;
      min-height: 100vh;
      border-radius: 0;
    }

    & .tile {
      height: auto;
      padding: 0.5rem 0.5rem;

      & span {
        font-size: 1rem;
      }
    }
  }
}
