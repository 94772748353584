$height: 1.5rem;

.chip-select-control {
  width: 100%;
  position: relative;
  background-color: #f3f5f7;
  border: 1px solid var(--bg-300);
  border-radius: 6px;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;

  &:focus-within {
    outline: none;
    background-color: white;
    box-shadow: 0px 0px 0px 3px rgba(182, 242, 255, 0.4);
    border-color: #078fdb;
  }

  & .adornment {
    margin-right: 0.5rem;

    & i {
      color: rgba(28, 27, 31, 1);
      opacity: 0.4;
    }
  }

  & .chips {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    & .chip {
      height: $height;
      padding: 0 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      border-radius: 5px;
      background: rgba(7, 143, 219, 0.16);

      & p {
        font-size: 0.875rem;
        line-height: 1;
        margin: 0;
      }

      & button {
        appearance: none;
        width: 0.75rem;
        height: 0.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        color: #9da1a9;
        margin-left: 0.25rem;
      }
    }
  }

  & .chip-select {
    width: 100%;
    max-width: 8rem;

    & input {
      appearance: none;
      width: 100%;
      height: $height;
      padding: 0 0.25rem;
      border: none;
      box-shadow: none;
      border-radius: 6px;
      background-color: transparent;

      &:focus {
        outline: none;
      }
    }
  }
}
