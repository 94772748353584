.menu-trigger {
  height: 2.5rem;
  padding: 0.25rem 0.25rem 0.25rem 0.75rem;
  border-radius: 0.375rem;
  background-color: var(--gray-base);
  transition: var(--quick-2) var(--expressive);

  &:hover {
    background-color: var(--gray-hover);
  }

  &:active {
    background-color: var(--gray-active);
  }

  &:not([class*="mnw"]) {
    min-width: 11rem;
  }

  & p {
    margin: 0;
  }
}
