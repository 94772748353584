.copy-input {
  width: 100%;
  height: 2.5rem;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  background-color: #f3f5f7;
  border: none;
  box-shadow: none;
  border-radius: 0.375rem;
  gap: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  transition: var(--quick-2) var(--expressive);

  &:hover {
    filter: brightness(0.95);
  }

  &:active {
    filter: brightness(0.9);
  }

  & p {
    white-space: nowrap;
    max-width: 230px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
  }

  & i {
    user-select: none;
  }
}
