.member-page {
  & .name {
    font-size: 3rem;
    font-weight: 700;
    margin-top: 2rem;
  }

  & .info-grid {
    width: auto;
    display: grid;
    grid-template-columns: 150px 1fr;
    row-gap: 1.5rem;
    margin-top: 2rem;

    & .label,
    & .value {
      font-size: 0.875rem;
      font-weight: 400;
      margin: 0;
    }

    & .label {
      color: var(--fg-secondary);
    }

    & .value {
      color: var(--fg-default);
    }
  }
}
