$link-height: 3rem;

.settings-sidebar {
  width: 256px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  border-radius: 1rem;
  padding: 1.5rem 1rem;
  background: linear-gradient(180deg, #d5e3ef 0%, #e3ebf2 100%);
  position: relative;

  & .links {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    position: sticky;
    top: 6rem;

    & .bg,
    & .settings-sidebar-link {
      transition: var(--quick-2) var(--expressive);
    }

    & .bg,
    & .settings-sidebar-link {
      border-radius: 0.625rem;
    }

    & .bg {
      width: 100%;
      height: $link-height;
      background-color: white;
      position: absolute;
      left: 0;
      right: 0;
      box-shadow: 0px 0px 1px rgba(11, 83, 168, 0.72),
        0px 2px 14px rgba(7, 70, 107, 0.09);
      z-index: 1;
    }

    & .settings-sidebar-link {
      width: 100%;
      height: $link-height;
      text-align: left;
      background-color: transparent;
      box-shadow: none;
      border: none;
      padding: 0 1rem;
      color: var(--fg-secondary);
      margin-bottom: 0.5rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      position: relative;
      z-index: 2;

      &:not(.current) {
        &:hover {
          background-color: rgba(186, 206, 225, 1);
        }

        &:focus {
          outline: none;
          background-color: rgba(162, 184, 204, 1);
        }
      }

      &.current {
        color: rgba(35, 43, 58, 1);
      }

      & span {
        line-height: 1;
      }
    }
  }
}
