.add-devices-modal {
  & .accounts-list {
    border: 1px solid var(--outer-border);

    & .disabled {
      opacity: 0.4;
      cursor: default !important;
      background: transparent !important;
    }

    & .row {
      height: 60px;
      display: flex;
      align-items: center;
      padding: 1rem;
      cursor: pointer;

      &:not(:first-of-type) {
        border-top: 1px solid var(--row-border);
      }

      &:hover {
        background: var(--row-hover-bg);
      }
    }

    & .linked-account {
      & .brand-image {
        flex: 0 0 120px;
        text-align: center;

        img {
          max-height: 50px;
          width: auto;
        }
      }
    }
  }
}
