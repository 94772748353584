.no-org-page {
  .title {
    color: var(--fg-default);
    font-size: 2rem;
    font-style: normal;
    margin-bottom: 0.625rem;
    font-weight: 600;
    line-height: 114%;
  }

  .description {
    line-height: 134%;
    margin: 0 0 2.5rem;
  }

  .choices {
    display: flex;
    margin-bottom: 16.25rem;
    gap: 1rem;

    .side {
      width: 300px;
      padding: 1rem;
      text-align: center;

      .side-icon {
        margin-bottom: 0.5rem;

        svg {
          scale: 2;

          * {
            fill: var(--accent);
          }
        }
      }

      h3 {
        font-size: 1rem;
        font-weight: 600;
        line-height: 134%;
        text-align: center;
        margin-bottom: 0.25rem;
      }

      .description {
        color: var(--fg-secondary);
        margin: 0;
      }

      button {
        margin-top: 1rem;
        width: 164px;
      }
    }

    .divider {
      width: 1px;
      margin: 2rem 0;
      background: var(--row-border);
    }
  }
}
