.org-setup-modal {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(black, 0.2);
  padding: 2rem;
  padding-top: 4rem;
  z-index: 4000;

  & .inner {
    width: 100%;
    max-width: 780px;
    background-color: var(--bg);
    border-radius: 1.5rem;
    box-shadow: 0px 2px 30px rgba(38, 41, 46, 0.35);
    overflow: hidden;
    will-change: transform;
    padding: 2rem;
  }

  & .inner-column {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }

  & .title,
  & .column-title {
    font-weight: 600;
    line-height: 114%;
    color: var(--fg);
    margin: 0;
  }

  & .title {
    font-size: 1.5rem;

    svg {
      margin-right: 0.5rem;
    }
  }

  & .column-title {
    font-size: 1.125rem;
  }

  & .text {
    font-size: 0.875rem;
    line-height: 134%;
    color: var(--fg-secondary);
    margin: 0;
  }

  & .caption {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 134%;
    color: var(--fg-tertiary);
    margin: 0;

    &.sm {
      font-size: 0.75rem;
    }
  }

  & .footer {
    width: 100%;
    padding: 1.5rem 0 0 0;
    border-top: 1px solid var(--row-border);
  }

  & .close-button {
    appearance: none;
    background-color: transparent;
    font-size: 1.125rem;
    font-weight: 600;
    color: var(--hyperlink-dark);
    padding: 0;
    margin: 0;
  }
}
