.invite-member-modal {
  height: 100%;
  --button-width: 114px;

  & .content {
    padding: 0 2rem;
  }

  & .inner {
    height: auto;
  }

  & .buttons {
    position: relative;

    & button {
      width: var(--button-width);
    }
  }

  & .role-switcher-menu {
    left: 0;
  }

  & .role-invite-label {
    color: var(--fg-secondary);
    font-size: 0.875rem;
  }

  & .invite-sent-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    height: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & .inner {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 1.5rem;
      line-height: 134%;

      & .title {
        margin: 13px 0;
        font-size: 1rem;
      }

      & .description {
        margin: 0;
        font-size: 0.875rem;
      }
    }

    & .footer {
      padding: 1rem;

      & button {
        width: var(--button-width);
      }
    }
  }
}
