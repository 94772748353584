.layout-control {
  // display: flex;
  // justify-content: flex-start;
  // align-items: flex-start;
  // flex-direction: row;
  display: grid;
  grid-template-columns: 23rem 1fr;
  column-gap: 2rem;
  padding-top: 2rem;
  padding-bottom: 8rem;
}

.sidebar {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

main {
  width: 100%;
}

// TODO!!!
// Mike uses a reversed breakpoint system where mobile is the default.
// Switch these values to match what he has (pretend to be mobile-first,
// don't actually do it).

@media only screen and (max-width: 1200px) {
  .layout-control {
    grid-template-columns: 20rem 1fr;
    column-gap: 1.5rem;
  }
}

@media only screen and (max-width: 1000px) {
  .layout-control {
    grid-template-columns: 19rem 1fr;
    column-gap: 1.5rem;
  }
}
