.device-accordion {
  width: 100%;
  border: 1px solid #d5d8dc;
  border-radius: 0.75rem;
  overflow: hidden;

  & .row {
    width: 100%;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    flex-direction: column;

    &:hover {
      background-color: #f4f6f8;
    }

    &:last-of-type {
      border-bottom: none;
    }

    &,
    & .icon {
      transition: var(--quick-2) var(--expressive);
    }

    &.open {
      &,
      & .trigger {
        background-color: #f4f6f8;
      }

      & .icon {
        transform: rotate(180deg);
      }
    }
  }

  & .trigger {
    appearance: none;
    width: 100%;
    background-color: transparent;
    padding: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    & .image {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 0.5rem;

      & img {
        height: 100%;
        max-width: 100%;
      }
    }

    & .name,
    & .caption {
      margin: 0;
    }

    & .name {
      font-size: 1rem;
      font-weight: 600;
      line-height: 134%;
    }

    & .caption {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #6e7179;
    }

    & .icon {
      color: #9da1a9 !important;
      transform-origin: center center;
    }
  }

  & .content {
    padding: 0 0.5rem 0 1.125rem;

    & .content-inner {
    }
  }

  & .row-action {
    appearance: none;
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    transition: var(--quick-2) var(--expressive);

    &:hover {
      background-color: #f4f6f8;
    }

    & span,
    & i {
      color: #078fdb;
    }

    & span {
      font-size: 0.875rem;
    }

    & i {
      transform: rotate(-90deg) !important;
    }
  }
}
